import React from 'react';

import Header from './components/Header';
import About from './components/About.jsx';
import Skills from './components/Skills';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <About />
        <Skills />
        <Contact />
      </main>
    </div>
  );
}

export default App;
