import React from 'react';

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <p>Email: your@email.com</p>
      {/* Add more contact info */}
    </section>
  );
};

export default Contact;
